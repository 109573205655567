<template>
  <div v-if="settings">
    <v-card>
      <v-card-title>
        <span>{{ $t('t.AllocationChorusSettings') }}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{ $icon('i.Checked') }}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{ $icon('i.Undo') }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-subheader>

          <span class="title">{{ $t('t.AllocationDispute') }}</span>
        </v-subheader>
        <!-- <v-switch
          :label="$t('t.Active')"
          dense
          v-model="settings.allocationIsActive"
        /> -->
        <grid
          item-min-width="310px"
          class="align-center"
        >
          <v-text-field
            v-model="settings.allocationBaseUrl"
            :label="$t('t.ApplicationBaseURL')"
            type="url"
          ></v-text-field>
          <number-field
            :number.sync="settings.allocationRetryNumber"
            :label="$t('t.RetryNumber')"
          />
          <number-field
            :number.sync="settings.allocationIntervalInMinute"
            :label="$t('t.IntervalInMinute')"
          />
          <v-text-field
            v-model="settings.allocationAuthUrl"
            :label="$t('t.UrlForSingleSignOnAuthentication')"
            type="url"
          ></v-text-field>
          <v-text-field
            v-model="settings.allocationLogin"
            :label="$t('t.ClientId')"
          ></v-text-field>
          <v-text-field
            v-model="settings.allocationPassword"
            :append-icon="showApiPassword ? $icon('i.Eye') : $icon('i.EyeOff')"
            :type="showApiPassword ? 'text' : 'password'"
            @click:append="showApiPassword = !showApiPassword"
            :label="$t('t.PasswordAPI')"
          >
          </v-text-field>
        </grid>
        <v-divider />
        <v-subheader>
          <span class="title">{{ $t('t.Chorus') }}</span>
        </v-subheader>
        <grid
          item-min-width="310px"
          class="align-center"
        >
          <v-switch
            :label="$t('t.ChorusFindInvoicesWithBusinessIdentifier')"
            dense
            v-model="settings.chorusFindInvoicesWithBusinessIdentifier"
          />
        </grid>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  mounted () {
    this.load()
  },
  activated () {
    this.load()
  },
  components: {
    Grid: () => import('@/components/grid'),
    NumberField: () => import('@/components/number-field')
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    }
  },
  data () {
    return {
      showApiPassword: false,
      initialSettings: null,
      required: [
        v => !!v || this.$t('t.IsRequired')
      ],
      settings: null
    }
  },
  methods: {
    async load () {
      const r = await this.$http().get('/core/v6/settings/allocation-settings')
      this.settings = r?.data
      this.initialSettings = Object.assign({}, r?.data)
    },
    async save () {
      const settingsCopy = Object.assign({}, this.settings)

      await this.$http().post('/core/v6/settings/allocation-settings', settingsCopy)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
  }
}
</script>

<style lang="stylus"></style>
